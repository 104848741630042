import axios from '@/utils/axios'

const api_all = {
    add_account(acc_id,ticker,dt,market) {
        return axios({
            url: '/add_account',
            method: 'POST',
            data: {
                acc_id:acc_id,
                ticker:ticker,
                create_dt:dt,
                market
            },
        })
    },
    
    get_option_list() {
        return axios({
            url: '/get_option_list',
            method: 'GET',
        })
    },
    get_option_delta_hedge(ticker, expiration) {
        return axios({
            url: '/get_option_delte_hedge?ticker=' + ticker + '&expiration=' + expiration,
            method: 'GET',
        })
    },

};

export default api_all
