<template>
  <div class="chart">
    <form @submit.prevent="search">
      <select v-model="paramTicker" @change="tickerOnChange($event)">
        <option disabled value="">Please select ticker</option>
        <option v-for="opt in optionList" :key="opt.ticker">
          {{ opt.ticker}}
        </option>
      </select>
      <select v-model="paramExpiration">
        <option disabled value="">Please select expiration</option>
        <option v-for="expiration in availableExpiration" :key="expiration"> 
          {{ expiration }}
        </option>
      </select>
      <button>Search</button>    
    </form>
    <p>Symbol:{{ticker}}</p>
    <p>Expirary Date:{{expiration}}</p>
    <p>Net Delta Hedge:{{NetDeltaHedge}}</p>
    <p>Last data extraction datetime:{{snapshotID}}</p>

    <ul>
      <li v-for="item in info" :key="item.key">
        {{ item.key }} :
        {{ item.val }}
      </li>
    </ul>

    <h3 align="left">Option Open Interest Districution</h3>
    <highcharts :options="chartOptions" 
    :callback="myCallback"></highcharts>
    <h3 align="left">Option Chain</h3>
    <b-table
      :items="table"
      :fields="fields"
      responsive="sm"
    >
      <template #thead-top="">
        <b-tr>
          <b-th ><span class="sr-only"></span></b-th>
          <b-th variant="warning" colspan="2">Call</b-th>
          <b-th variant="success" colspan="2">Put</b-th>
        </b-tr>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: 'Chart',
  props: {
    msg: String
  },
  data() {
    return {
      paramTicker: 'NVDA',
      paramExpiration: '',
      
      optionList: [
        {'ticker':'NVDA', 'expirationList':['2022-01-01', '2022-01-02']},
      ],
      availableExpiration : null,

      ticker: 'NVDA',
      expiration: '2022-04-28',
      strikeLabel : [],
      callLabel : [],
      putLabel : [],
      idxmin : 21,
      minStrike : 0,
      NetDeltaHedge : 0,
      snapshotID: '',
      table: [
        {'Strike': 155.0,
        'Open Int_call': 3.0,
        'Delta_call': 0.36,
        'Open Int_put': 489.0,
        'Delta_put': -0.637},
                 ],
        fields: [
          'Strike', 
          { key: 'Open Int_call', label: 'Open Interest' },
          { key: 'Delta_call', label: 'Delta' },
          { key: 'Open Int_put', label: 'Open Interest' },
          { key: 'Delta_put', label: 'Delta' },

        ],

      chartOptions: {
        chart: {
            type: 'column'
        },
        plotOptions: {
            column: {
                stacking: 'normal'
            }
        },
        colors: ['#fb9906', '#66CC00',],
        title: {
            text: '',
        },

        xAxis: {
            categories: [],
            title:{
              text: "Strike Price"
            },
            labels :{
              rotation: 45
            }
        },
        yAxis: {
          title: {
            text: "Open Interest"
          }
        },

        credits: {
            enabled: false
        },
        series: [{
            name: 'Call',
            data: [],
        }, {
            name: 'Put',
            data: [],
        }], 

        legend: {
          layout: "vertical",
          align: "right",
          verticalAlign: "middle"
        },
      }
    };
  },
  methods: {
    myCallback() {
      console.log("this is callback function");
    },
    tickerOnChange(){
      let temp = JSON.parse(JSON.stringify(this.optionList))
      this.availableExpiration = temp.find((t)=> t.ticker==this.paramTicker).expirationList
    },
    search() {
      this.$api.api_all.get_option_delta_hedge(this.paramTicker, this.paramExpiration).then((response)=>{
        this.callLabel = response.data.callLabel
        this.putLabel = response.data.putLabel
        this.strikeLabel = response.data.strikeLabel
        this.idxmin = response.data.idxmin
        this.minStrike = response.data.minStrike
        this.snapshotID = response.data.snapshotID
        this.NetDeltaHedge = response.data.NetDeltaHedge
        this.table = response.data.table
        this.info = response.data.info.data
        this.ticker = this.paramTicker
        this.expiration = this.paramExpiration

        this.chartOptions.series = [{
              name: 'Call',
              data: this.callLabel
          }, {
              name: 'Put',
              data: this.putLabel,
          }]
        this.chartOptions.xAxis = {
            categories: this.strikeLabel,
            title:{
              text: "Strike Price"
            },
            labels :{
              rotation: 45
            },
            plotLines: [{
                value: this.idxmin,
                width: 1,
                color: 'red',
                dashStyle: 'dash',
                zIndex: 5,
                label: {
                    align: 'bottom',
                    rotation: 0,
                    y: 12,
                    x: 10,
                    useHTML: true,
                    text: '<div style="border-style: solid;border-width: 1px;background-color:white;border-color:red;font-size: 10px;text-align: center;width: 80px;">Current: $' + this.minStrike + '</div>',
                }
            }]
        }

        }).catch(()=>{
        })

    },
    async fetchOptionList() {
      this.paramTicker= ''
      this.paramExpiration = ''

      this.$api.api_all.get_option_list().then((response)=>{
        this.optionList = response.data
        }).catch(()=>{
        })
    }
  },
  created() {
    this.fetchOptionList()

    this.paramTicker= 'NVDA'
    this.paramExpiration = '2022-05-20'
    this.search()
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
