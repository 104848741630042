<template>
  <div id="app">
    <Dashboard msg="Welcome to Analytic Tool"/>
    <Chart msg="Chart"/>
  </div>
</template>

<script>
import Dashboard from './components/Dashboard.vue'
import Chart from './components/Chart.vue'

export default {
  name: 'AnalyticTool',
  components: {
    Dashboard,
    Chart
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
