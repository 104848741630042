import axios from 'axios' 

axios.defaults.baseURL = "https://api.jiazheng2222.com/"; 

axios.interceptors.request.use((request) => {
    var token = JSON.parse(localStorage.getItem('TOKEN'));
    if (token) {
        token = "Bearer " + token;
        request.headers.common['Authorization'] = token;
    }
    if(request.method  === 'post'){
        //request.data = qs.stringify(request.data);
    }
    return request;
},(error) =>{
    return Promise.reject(error);
});

export default axios 

